import React, { useState } from 'react';
import {animatedAlertbox} from "../SweetAlertItem/SweetAlert";
import findCertificatesByGuid from '../VenafiServiceCalls/findCertificatesByGuid';
import {animatedConfirmbox,animatedSuccessAlert} from "../SweetAlertItem/SweetAlert";
import DatePicker from "react-datepicker";
import "./FilterTable.css";
import "react-datepicker/dist/react-datepicker.css";

let FilterTableSearch = ({ handleSearch, clearSearch ,selctedRows, rows, setRowData, showModel,access_token,user, getRes,setLoading}) => {
   const [sfield, setSfield] = React.useState('');
   const [efield, setEfield] = React.useState('cn');
   const [startDate, setStartDate] = useState(new Date());
   const [placeHolder, setPlaceHolder] = React.useState('Ex-abc.xyz-123.nike.com or abc');

   let handleSubmit = (e) => {
      var search = (efield === "valid_to_day") ? JSON.stringify(startDate).split('T')[0].substring(1) : sfield;
      e.preventDefault();
      ((sfield !=="" && sfield !==null) || efield === "valid_to_day") ? handleSearch(efield, search) : animatedAlertbox('Please enter search value to continue');
   };

   let handleKeypress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

   let handleClear = () => {
      setSfield('');
      setEfield('cn');
      setPlaceHolder('Ex-abc.xyz-123.nike.com or abc');
      clearSearch();
   };

   //Handle validation for updating records based on slack channel and distribution group
   let handleValidate = () => {
      if(selctedRows ===0)
      {
         animatedAlertbox('At least one record should be selected to update.');
      }
      else if(selctedRows ===1)
      {
         if(rows.length===1){
            setRowData(rows[0]);
            showModel(true);
         }
         else{
            let array=[rows];
            setRowData(array[0]);
            showModel(true);
         }
      }
      else
      {
         let uniqueSlackDetailsArray = [...new Set(rows.map(item=>
         {
           if (item.slack_details && item.slack_details.slackChannel)
               return item.slack_details.slackChannel;
           else
               return 'Empty';
         }))];
         let uniqueDistributionListArray = [...new Set(rows.map(item=>
         {
          if (item.contact_details && item.contact_details.distributionList)
             return item.contact_details.distributionList;
          else
             return'Empty';
         }))];
         (uniqueSlackDetailsArray.length===1 && uniqueDistributionListArray.length ===1) ? showModel(true) : animatedAlertbox('One or more record that are selected is not matching with the slack channel or distribution list.');
      }
   };

   let handleForceSync=()=>
   {
      if(selctedRows ===0)
      {
         animatedAlertbox('At least one record should be selected to Force sync.');
      }
      else
      {
         let selectedItems = (Array.isArray(rows)) ? rows : [rows];
         const alertMessage= 'You have selected more than 100 records, Are you sure to force sync up?';
         (selectedItems.length > 100) ? animatedConfirmbox(alertMessage,selectedItems,submitForceSync) : submitForceSync(selectedItems);
      }
   }

   let submitForceSync =async (selectedItems)=>
   {
      setLoading(true);
      for(let item of selectedItems)
      {
      const data = 
         {
         cn: item.cn,
         user:user,
         valid_to: item.valid_to,
         guid :item.guid,
         };
         let res=await findCertificatesByGuid(access_token,data);
         console.log("Force sync status for Cert : " + item.cn + " is: " + res.body);
      } 
      setLoading(false);
      getRes();
      animatedSuccessAlert('Renewed certificate details updated!');
   }

   let setPlaceValue=(value)=>
   {
      if(efield==='valid_to_day'){
         setSfield('');
      }
      setEfield(value);

      if(value==='cn')
         setPlaceHolder('Ex-abc.xyz-123.nike.com or abc');
      else if(value==='owner')
         setPlaceHolder('Ex-john.doe@nike.com or john');
      else if(value==='parent_dn')
         setPlaceHolder("Ex-\\VED\\Policy\\Retail or Retail");
      else if(value==='valid_to_day')
         setPlaceHolder('yyyy-MM-dd')
   }

   return (
      <div>
         <div className="ncss-row">
            <div className="ncss-col-sm-3">
               <div className="SearchWrapper">
               <label className="ncss-label">Search By</label>
               <div className="ncss-select">
                  <select
                     style={{ width: '100%' }}
                     className="ncss-select-menu pt3-sm pb2-sm border-white"
                     value={efield}
                     onChange={(e) => setPlaceValue(e.target.value)}
                  >
                     <option value="cn">Certificate Name</option>
                     <option value="owner">Owner</option>
                     <option value="parent_dn">Parent DN</option>
                     <option value="valid_to_day">Expires</option>
                  </select>
               </div>
               </div>
            </div>
            <div className="ncss-col-sm-4">
            <div className="SearchWrapper">
               {
                  {
                     'cn':<>
                        <label className="ncss-label">Search</label>
                        <input
                           style={{ width: '60%' }}
                           placeholder={placeHolder}
                           className="ncss-input pr4-sm pt2-sm pb2-sm"
                           type="text"
                           value={sfield}
                           onChange={(e) => setSfield(e.target.value)}
                           onKeyPress={handleKeypress}
                        />
                     </>,
                     'owner':<>
                     <label className="ncss-label">Search</label>
                     <input
                        style={{ width: '60%' }}
                        placeholder={placeHolder}
                        className="ncss-input pr4-sm pt2-sm pb2-sm"
                        type="text"
                        value={sfield}
                        onChange={(e) => setSfield(e.target.value)}
                        onKeyPress={handleKeypress}
                     />
                  </>,
                  'parent_dn':<>
                  <label className="ncss-label">Search</label>
                  <input
                     style={{ width: '60%' }}
                     placeholder={placeHolder}
                     className="ncss-input pr4-sm pt2-sm pb2-sm"
                     type="text"
                     value={sfield}
                     onChange={(e) => setSfield(e.target.value)}
                     onKeyPress={handleKeypress}
                  />
               </>,
                  'valid_to_day':<>
                  <label className="ncss-label">Search</label>
                    <DatePicker 
                    selected={startDate}
                    style={{ width: '60%' }}
                    dateFormat="yyyy-MM-dd"
                    className="ncss-input pr4-sm pt2-sm pb2-sm"
                    placeholderText={placeHolder}
                    onChange={(date) => {setStartDate(date); setSfield(date);}}>
                  </DatePicker>
                     </>
                  }[efield]
               }
            </div>
            </div>

         </div>{' '}
         <div className="ncss-row">
            <div className="ncss-col-sm-1 buttonWrapper">
                  <button
                      style={{ width:'100%' }}
                      variant="contained"
                      className="ncss-btn-primary-dark"
                      onClick={handleValidate}
                  >
                     Bulk Update
                  </button>
               </div>         
               <div className="ncss-col-sm-1 buttonWrapper">
                  <button
                      style={{ width:'100%' }}
                      variant="contained"
                      className="ncss-btn-primary-light"
                      onClick={handleForceSync}
                      data-toggle="tooltip" 
                      data-placement="top" 
                      title='Force Sync up recently updated / renewed certificate details with this dashboard'
                      >
                     Force Sync
                  </button>
               </div>
               <div className="ncss-col-sm-1 buttonWrapper">
               <button
                  style={{ width: '100%' }}
                  type="submit"
                  variant="contained"
                  className="ncss-btn-primary-dark"
                  onClick={handleSubmit}
               >
                  Search
               </button>
               </div>       
               <div className="ncss-col-sm-1 pt2-sm  buttonWrapper">
               <button
                  style={{ width: '100%' }}
                  variant="contained"
                  className="ncss-btn-primary-light"
                  onClick={(e) => {
                     handleClear(e);
                  }}
               >
                  Clear{' '}
               </button>
               </div>   
         </div>
      </div>
   );
};

export default FilterTableSearch;
