const isProd = window.location.hostname === 'certificates.nike.com';
export default async function getCertificates(access_token,limit, key) {
   console.log("is prod", isProd)
   const env = isProd ? 'prod' : 'test';
   let b = {};
   b.limit = limit;
   if(key){
      b.paginationKey = key;
   }
   const response = await fetch(
      `https://sro-team-${env}.nikecloud.com/ssl-dashboard-api/cert/getdata`,
      {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            'okta-token' : 'Bearer '+access_token
          },
         cache: 'no-cache',
         body: JSON.stringify(b),
      }
   );
   return response.json();
}
