const isProd = window.location.hostname === 'certificates.nike.com';

export default async function searchCertificates(access_token,entity, searchTerm) {
   const env = isProd ? 'prod' : 'test';
   const response = await fetch(
       `https://sro-team-${env}.nikecloud.com/ssl-dashboard-api/cert/search/${entity}/${searchTerm}`,
       {
          method: 'GET',
          cache: 'no-cache',
          headers: {
           'Content-Type': 'application/json',
           'okta-token' : 'Bearer '+access_token
        },
       }
    );
   return response.json();
 }