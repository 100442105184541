const isProd = window.location.hostname === 'certificates.nike.com';

export default async function patchCertificates(access_token, data) {
   const env = isProd ? 'prod' : 'test';

  const response = await fetch(
      `https://sro-team-${env}.nikecloud.com/ssl-dashboard-api/cert/updatedata`,
      {
         method: 'PATCH',
         cache: 'no-cache',
         headers: {
          'Content-Type': 'application/json',
          'okta-token' : 'Bearer '+access_token
       },
         body: JSON.stringify(data),
      }
   );
  return response.json();
}
