import React, { useState, useEffect } from 'react';
import VenafiTable from 'components/VenafiTable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Header from 'components/Header/Header.js';
import { generateToken } from 'components/Login/login';
import ExpiringSoonTable from 'components/ExpiringSoonTable';
import sreLogo from './sreLogo.png';
import { OktaConfig } from 'config/okta';

export default function Dashboard() {
   const [authRes, setAuthRes] = useState({});
   const [expiry, setExpiry] = useState('');
   const [accessToken, setAccessToken] = useState('');
   const [user, setUser] = useState('');
   const [userId, setUserId] = useState('');
   const [errorCount] = useState(() => {
      const savedErrorCount = Number(localStorage.getItem('errorCount'));
      console.log('savedErrorCount', savedErrorCount);
      return savedErrorCount || 0;
   });
   console.log('errorCount is :', errorCount);
   useEffect(() => {
      async function getAuthDetails() {
         console.log('generating token');
         let res = await generateToken();
         setAuthRes(res);
      }
      getAuthDetails();
   }, []);

   useEffect(() => {
      setExpiry(authRes.expiry);
      setAccessToken(authRes.access_token);
      setUser(authRes.user);
      setUserId(authRes.userId);
      console.log('authRes ', authRes);

      //authRes.error === 'invalid_grant'
      if (errorCount < 1 || authRes.expiry <= Date.now()) {
         window.location.href = OktaConfig.redirectUrl.substring(
            0,
            OktaConfig.redirectUrl.length - 8
         );
         return;
      }
      if (!authRes.accessToken && authRes.error) {
         console.log('Access denied ');
         window.location.href = window.location.href =
            OktaConfig.redirectUrl.substring(
               0,
               OktaConfig.redirectUrl.length - 8
            ) + 'accesserror';
         return;
      }
   }, [authRes]);

   return expiry > Date.now().toString() ? (
      <>
         <Header user={user} />
         <div>
            <Tabs>
               <TabList
                  style={{ display: 'flex', justifyContent: 'space-between' }}
               >
                  {/* <Tab>IAM Table</Tab>
                  <Tab> ACM Table</Tab> */}
                  <div>
                     <Tab> Expiring Soon </Tab>
                     <Tab> Venafi Search</Tab>
                  </div>
                  <div>
                     <a
                        target="_blank"
                        href="https://confluence.nike.com/display/CCB/Certificate+Dashboard"
                        style={{
                           fontWeight: 'normal',
                           textDecoration: 'none',
                           color: '#212529',
                           display: 'inline-block',
                           border: '1px solid transparent',
                           borderBottom: 'none',
                           bottom: '-1px',
                           position: 'relative',
                           padding: '6px 12px',
                           cursor: 'pointer',
                        }}
                     >
                        Help
                     </a>
                  </div>
               </TabList>

               {/* <TabPanel>
                  <IAMTable saveCookies />
               </TabPanel>
               <TabPanel>
                  <ACMTable saveCookies />
               </TabPanel> */}
               <TabPanel>
                  <ExpiringSoonTable
                     access_token={accessToken}
                     user={user}
                     expiry={expiry}
                     userId={userId}
                  />
               </TabPanel>
               <TabPanel>
                  <VenafiTable
                     access_token={accessToken}
                     user={user}
                     expiry={expiry}
                     userId={userId}
                  />
               </TabPanel>
            </Tabs>
         </div>
      </>
   ) : (
      <div
         style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
         }}
      >
         <img src={sreLogo} alt={'SRE'} />
         <h4>
            Don't have access? Request the AD Group on ID Locker:
            Nike.SRE.SSLDashboard.Users.USA
         </h4>
      </div>
   );
}

export function AccessError() {
   localStorage.removeItem('errorCount');
   return (
      <div
         style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
         }}
      >
         <img src={sreLogo} alt={'SRE'} />
         <h4>
            Don't have access? Request the AD Group on ID Locker:
            Nike.SRE.SSLDashboard.Users.USA
         </h4>
      </div>
   );
}
