import React, { useEffect, useState } from 'react';

import ReactTable from 'react-data-table-component';
import getCertificates from '../VenafiServiceCalls/getCertificates.js';
import { columns, ExpandableRow } from '../VenafiTable/TableOptions';
import AdminModal from '../VenafiTableUpdate/AdminModal.js';
import { OktaConfig } from 'config/okta';
import BeatLoader from "react-spinners/BeatLoader";

export default function ExpiringSoonTable({ access_token, user, expiry, userId }) {
   const [data, setData] = useState([]);
   const [rowData, setRowData] = useState({});
   const [loading, setLoading] = useState(false);
   const [modalShow, setModalShow] = useState(false);

   useEffect(() => {
      if(expiry <= Date.now()) {
         window.location.href = OktaConfig.redirectUrl.substring(
            0,
            OktaConfig.redirectUrl.length - 8
         );
      }
      loadDashboard();
      // eslint-disable-next-line
   }, []);

   const loadDashboard = async () => {
      let initialData = [];
      setLoading(true);
      await getCertificates(access_token, 50).then((res) => {
         console.log(' <> ', res);
         if(res.body && res.body.items) {
         let filteredData = res.body.items.filter(
            (item) =>
               new Date(item.valid_to) <= new Date(addDays(item.valid_to, 90))
         );
     
         console.log('&*', filteredData);
         setLoading(false);
         setData(filteredData);
 }
         initialData.push(res.body.items);
      });
   };
   const addDays = (expdate, days) => {
      let date = new Date(expdate);
      date.setDate(date.getDate() + days);
      return date;
   };

   let updateHandler = (status) => {
      if (status <= 210) {
         console.log('Success', status);
      } else {
         console.log('Failure', status);
      }
      loadDashboard();
   };

   return (<>
      <div className="ncss-row">
         <div>
            <AdminModal
               show={modalShow}
               onHide={() => setModalShow(false)}
               getRes={updateHandler}
               row={rowData}
               access_token={access_token}
               user={user}
               setLoading={setLoading}
            />

         </div >
         <div style={{padding: '0 5% 5% 5%'}}>
         <ReactTable
            title="Expiring Venafi Certificates"
            columns={columns}
            data={data}
            access_token={access_token}
            defaultSortField="valid_to"
            defaultSortAsc={true}
            expandableRows={true}
            expandOnRowClicked
            expandableRowsComponent={<ExpandableRow />}
            striped={true}
            progressPending={loading}
            progressComponent={<BeatLoader color="#00BFFF" size={20} margin={10} />}
            dense
            onRowDoubleClicked={(row) => {
               setModalShow(true);
               setRowData(row);
            }}
            highlightOnHover={true}
            fixedHeader={true}
            keyField="guid"
         />
         </div>
      </div>
   </>);
}
